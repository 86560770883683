<template>
  <component :is="layout"></component>
</template>

<script>
import { defineComponent, reactive, watch, toRefs } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "App",
  setup() {
    const route = useRoute();
    const state = reactive({
      layout: "empty-layout",
    });
    watch(() => route.meta?.layout, (newVal, oldVal) => {
      state.layout = newVal || "empty-layout";
    }, { immediate: true });
    return {
      ...toRefs(state),
    };
  },
});
</script>
<style lang="less">
@success: #52c41a;
@info: #1890ff;
@warning: #faad14;
@error: #ff4d4f;
@textposition: left, right, center;
@spacing: 5px;

.colors() {
  success: @success;
  info: @info;
  warning: @warning;
  error: @error;
}

.textsizes() {
  h1: 2.5rem;
  h2: 2.25rem;
  h3: 2rem;
  h4: 1.75rem;
  h5: 1.5rem;
  h6: 1.25rem;
}

each(range(4), {
  .ma-@{value} {
    margin: (@value * @spacing) !important;
  }

  .mt-@{value} {
    margin-top: (@value * @spacing) !important;
  }

  .mr-@{value} {
    margin-right: (@value * @spacing) !important;
  }

  .mb-@{value} {
    margin-bottom: (@value * @spacing) !important;
  }

  .ml-@{value} {
    margin-left: (@value * @spacing) !important;
  }

  .pa-@{value} {
    padding: (@value * @spacing) !important;
  }

  .pt-@{value} {
    padding-top: (@value * @spacing) !important;
  }

  .pr-@{value} {
    padding-right: (@value * @spacing) !important;
  }

  .pb-@{value} {
    padding-bottom: (@value * @spacing) !important;
  }

  .pl-@{value} {
    padding-left: (@value * @spacing) !important;
  }

  .w-@{value} {
    width: (@value * @spacing) !important;
  }

  .h-@{value} {
    height: (@value * @spacing) !important;
  }
});

each(.colors(), .(@v, @k, @i) {
    .text-@{k} {
      color: @v !important;
    }

    .btn-@{k} {
      color: #fff !important;
      border-color: @v !important;
      background-color: @v !important;
    }

    .bg-@{k} {
      background-color: @v !important;
    }
  });

each(@textposition, {
  .text-@{value} {
    text-align: @value !important;
  }
});

each(.textsizes(), .(@v, @k, @i) {
    .text-@{k} {
      font-size: @v !important;
    }
  });

.inaline {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.intwoline {
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

#nprogress .bar {
  background: red !important; //自定义颜色
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.font-weight-bold {
  font-weight: bold !important;
}

.click {
  cursor: pointer !important;
}

.underline {
  text-decoration: underline;
}

.disabled {
  cursor: not-allowed !important;
}

.w_100 {
  width: 100%;
}

.h_100 {
  height: 100%;
}
</style>
