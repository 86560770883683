<template>
  <div v-if="fetched">
    <a-form ref="matchRef" name="matchForm" :model="matchForm" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }"
      autocomplete="off">
      <a-form-item label="赛事规程" name="guicheng">
        <div class="main">
          <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig"
            :mode="editorMode" />
          <Editor style="height: 500px; overflow-y: hidden" v-model="matchForm.guicheng" :defaultConfig="editorConfig"
            :mode="editorMode" @onCreated="handleCreated" />
        </div>
      </a-form-item>
      <a-form-item label="报名截止时间" name="sign_end">
        <a-date-picker v-model:value="matchForm.sign_end" :locale="locale" value-format="YYYY-MM-DD HH:mm:ss"
          style="width:400px;" :showTime="true" :allowClear="false" />
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 2, span: 22 }">
        <a-button type="primary" @click="updateMatch">更新</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import {
  defineComponent,
  ref,
  shallowRef,
  toRefs,
  reactive,
  watch,
  computed,
  onMounted,
  onActivated,
  onBeforeUnmount,
  getCurrentInstance,
  toRaw,
} from "vue";
import "@wangeditor/editor/dist/css/style.css";
import { DomEditor } from "@wangeditor/editor";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import _ from "underscore";
export default defineComponent({
  name: "Home",
  components: {
    Editor,
    Toolbar,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const matchRef = ref();
    const editorRef = shallowRef();
    const toolbarConfig = {
      toolbarKeys: [
        "bold",
        "italic",
        "bulletedList",
        "numberedList",
        "undo",
        "redo",
        "fullScreen",
      ],
    };
    const editorConfig = {};
    const state = reactive({
      matchForm: {
        guicheng: "",
        sign_end: "",
      },
      fetched: false,
    });
    onActivated(() => {
      getData();
    });
    onBeforeUnmount(() => {
      const editor = editorRef.value;
      if (editor == null) return;
      editor.destroy();
    });
    const getData = () => {
      state.fetched = false;
      proxy.$api.doAPI("/match/info").then((res) => {
        state.fetched = true;
        if (res.code === 0) {
          console.log(res.data.match);
          state.matchForm = {
            guicheng: res.data.match.guicheng,
            sign_end: proxy.$utils.dateFormat(res.data.match.sign_end, 'YYYY-MM-DD HH:mm:ss'),
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.fetched = true;
        console.error(err);
      });
    };
    const updateMatch = () => {
      state.updating = false;
      proxy.$api.doAPI("/match/update", state.matchForm).then((res) => {
        state.updating = true;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.updating = true;
        console.error(err);
      });
    }
    const handleCreated = (editor) => {
      editorRef.value = editor; // 记录 editor 实例，重要！
    };
    const testEditor = () => {
      console.log(editorRef.value.getConfig().toolbarKeys);
      const toolbar = DomEditor.getToolbar(editorRef.value);

      const curToolbarConfig = toolbar.getConfig();
      console.log(curToolbarConfig.toolbarKeys); // 当前菜单排序和分组
    };
    return {
      ...toRefs(state),
      locale,
      matchRef,
      editorRef,
      editorMode: "default",
      // editorMode: 'simple',
      toolbarConfig,
      editorConfig,
      getData,
      updateMatch,
      handleCreated,
      testEditor,
    };
  },
});
</script>
<style lang="less" scoped>
.main {
  border: 1px solid #ccc;
}
</style>
