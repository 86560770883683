import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
const app = createApp(App);
app.use(Antd);

app.config.productionTip = false;
import BasicLayout from "./layouts/BasicLayout";
import EmptyLayout from "./layouts/EmptyLayout";
app.component("basic-layout", BasicLayout);
app.component("empty-layout", EmptyLayout);
import api from './request'
app.config.globalProperties.$api = api;
import constant from './constant';
app.config.globalProperties.$constant = constant;
import utils from './utils';
app.config.globalProperties.$utils = utils;

app.config.globalProperties.$filters = {
  dateFormat: utils.dateTime,
}
app.use(router);
app.use(store);
app.mount("#app");
