import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";

import store from '../store';
import api from '../request'
import utils from '../utils';
import NProgress from "nprogress";
import "nprogress/nprogress.css";
NProgress.configure({
  easing: "ease", // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
});

import Home from "@/views/home.vue";
import SignData from "@/views/signdata.vue";
import Login from "@/views/login.vue";
import NotFound from "@/views/404.vue";

const routes = [
  {
    path: "/",
    redirect: "/home"
  },
  //首页
  {
    name: "home",
    path: "/home",
    component: Home,
    meta: { layout: "basic-layout" },
  },
  //报名数据
  {
    name: "signdata",
    path: "/signdata",
    component: SignData,
    meta: { layout: "basic-layout" },
  },
  //登录
  {
    name: "login",
    path: "/login",
    component: Login,
    meta: { layout: "empty-layout", requireAuth: false },
  },
  //404
  {
    name: "notfound",
    path: "/:catchAll(.*)",
    component: NotFound,
    meta: { layout: "empty-layout", requireAuth: false },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});
router.beforeEach((to, from, next) => {
  console.log(to);
  NProgress.start(); // 路由跳转前钩子函数中 - 执行进度条开始加载
  let v = localStorage.getItem("zzdxs_admin.version") || "0";
  api.get(`/admin/init?t=${Date.now()}&v=${v}`).then((data) => {
    // console.log(data);
    NProgress.done();
    if (data.code === "newversion") {
      localStorage.setItem("zzdxs_admin.version", data.version);
      window.location.href = to.fullPath;
      return;
    }
    setTimeout(async () => {
      let { userid = "", username = "", name = "", role = "", needlogin, } = data.data;
      await utils.setStore({ userid, username, name, role });
      if (needlogin) localStorage.clear();
      if (!localStorage.getItem("zzdxs_admin.token") && to.meta?.requireAuth !== false) {//需要登录 无token
        return next("/login");
      }
      if (localStorage.getItem("zzdxs_admin.token") && (to.path === "/login" || to.path === "/")) {
        return next("/home");
      }
      if (to.meta?.requireAuth !== false) {//需要登录
        store.dispatch("menu/checkMenu", to.path).then((authorized) => {
          if (authorized === true) next();
          else if (typeof authorized === "string") next(authorized);
          else next("/home");
        });
      } else if (to.path === "/") {
        next("/home");
      } else {
        next();
      }
    }, 0);
  }).catch((e) => {
    NProgress.done();
    console.error(e);
  });
});
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done();
});
window._doRouterPush = (path) => {
  if (router.currentRoute.path === path) return;
  router.push({ path }).catch(console.error);
};
window._doRouterReplace = (path) => {
  if (router.currentRoute.path === path) return;
  router.replace({ path }).catch(console.error);
};

export default router;
