const state = {
  userid: "",
  username: "",
  name: "",
  role: "",
};
const mutations = {
  set_user(state, payload) {
    state.userid = payload.userid || "";
    state.username = payload.username || "";
    state.name = payload.name || "";
    state.role = payload.role || "";
  },
};
const actions = {
  setUser({ commit }, payload) {
    commit('set_user', payload);
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
