<template>
  <div class="login">
    <div class="left">
    </div>
    <div class="right">
      <div class="loginContainer">
        <h1 style="color: #fff; text-align: left; font-size: 40px">城市定向赛-郑州站</h1>
        <a-card title="登录" :bordered="false" style="width: 400px">
          <a-form ref="formRef" name="loginForm" :model="loginForm" @finish="doLogin">
            <a-form-item name="username" :rules="[{ required: true, message: '请输入账号' }]">
              <a-input v-model:value="loginForm.username" placeholder="请输入账号" :disabled="logining">
                <template #prefix>
                  <UserOutlined />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item name="password" :rules="[{ required: true, message: '请输入密码' }]">
              <a-input-password v-model:value="loginForm.password" placeholder="请输入密码" :disabled="logining">
                <template #prefix>
                  <LockOutlined />
                </template>
              </a-input-password>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" style="width: 100%" html-type="submit" :loading="logining">
                登录
              </a-button>
            </a-form-item>
          </a-form>
        </a-card>
      </div>
    </div>
  </div>
</template>
<script>
import { UserOutlined, LockOutlined, KeyOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "underscore";
export default defineComponent({
  name: "Login",
  components: { UserOutlined, LockOutlined, KeyOutlined },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const initForm = {
      username: "",
      password: "",
    };
    const state = reactive({
      loginForm: {},
      logining: false,
    });
    onMounted(() => {
      console.log("onMounted");
      state.loginForm = _.extend({}, initForm);
    });
    onActivated(() => {
      console.log("onActivated");
    });
    const doLogin = () => {
      state.logining = true;
      proxy.$api.doAPI("/login", { loginForm: state.loginForm, ...route.query }).then((res) => {
        state.logining = false;
        if (res.code === 0) {
          localStorage.setItem("zzdxs_admin.token", res.data.token);
          proxy.$utils.setStore(res.data);
          router.replace({ path: "/home" }).catch(console.error);
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.logining = false;
        console.error(err);
      });
    };
    return {
      ...toRefs(state),
      doLogin,
    };
  },
});
</script>
<style lang="less" scoped>
.login {
  background: url(~@/assets/login_bg.jpg) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100vh;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    flex: 1;
    width: 400px;
    text-align: left;
    margin-left: 150px;

    h1,
    h2,
    p {
      color: #fff;
    }
  }

  .right {
    flex: 1;
    display: flex;
    justify-content: center;
  }

}


.ant-carousel :deep(.slick-slide) {
  height: 400px;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

:deep(.ant-card-head-title) {
  text-align: center;
  font-size: 30px;
}

:deep(.ant-form-item-control-input-content) {
  display: flex;
  justify-content: space-between;
}

:deep(.ant-spin-nested-loading) {
  width: 26%;
}
</style>
