import axios from 'axios'
import { Modal, message } from "ant-design-vue"
const instance = axios.create({
  baseURL: process.env.BASE_API,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json;charset=utf-8' }
});
instance.interceptors.request.use(config => {
  config.headers.Authorization = `${localStorage.getItem('zzdxs_admin.token')}`;
  return config;
}, error => {
  return Promise.reject(error);
})
//响应拦截器
instance.interceptors.response.use(response => {
  if (response.data.code === 'nopower') {
    Modal.warning({
      title: '提示',
      content: response.data.msg,
      onOk() {
        localStorage.clear();
        window._doRouterPush("/login");
      },
    });
    return Promise.reject(new Error(response.data.msg));
  } else if (response.data.code === 'refresh') {
    Modal.warning({
      title: '提示',
      content: response.data.msg,
      onOk() {
        window.location.href = window.location.href;
      },
    });
    return Promise.reject(new Error(response.data.msg));
  }
  return response;
}, error => {
  message.error(error.message);
  return Promise.reject(error);
});
// 封装get方法
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance.get(url, { params: params }).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    })
  })
}
// 封装post方法
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    });
  })
}
export function doUpload(url, formData, onUploadProgress = (progressEvent) => {
  console.log(progressEvent.loaded, progressEvent.total);
}) {
  return new Promise((resolve, reject) => {
    instance.post("/admin" + url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `${localStorage.getItem('zzdxs_admin.token')}`,
      }, onUploadProgress
    }).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    });
  })
}
export function doAPI(url, Data = {}) {
  return post("/admin" + url, Data);
}
export function doAPI2(url, Data = {}) {
  return post(url, Data);
}
export default {
  get, post, doUpload, doAPI, doAPI2,
};
