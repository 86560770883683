<template>
  <div class="d-flex align-center mb-2">
    <span style="width:44px;">查询：</span>
    <a-input-search v-model:value="queryForm.searchtext" placeholder="输入队伍名称、手机号搜索" enter-button="搜索" @search="getData" />
  </div>
  <div class="d-flex align-center mb-2">
    <span>线路：</span>
    <a-radio-group v-model:value="queryForm.lineid" @change="getData">
      <a-radio-button value="0">全部线路</a-radio-button>
      <a-radio-button v-for="line in match_lines" :key="line._id" :value="line._id">
        {{ line.name }}
      </a-radio-button>
    </a-radio-group>
  </div>
  <div class="d-flex align-center mb-2">
    <span>状态：</span>
    <a-radio-group v-model:value="queryForm.paystate" @change="getData">
      <a-radio-button value="0">全部</a-radio-button>
      <a-radio-button value="1">已支付</a-radio-button>
      <a-radio-button value="2">未支付</a-radio-button>
    </a-radio-group>
  </div>
  <div class="d-flex align-center mb-2">
    <span>来源：</span>
    <a-radio-group v-model:value="queryForm.from" @change="getData">
      <a-radio-button value="0">全部</a-radio-button>
      <a-radio-button value="1">线上(微信)</a-radio-button>
      <a-radio-button value="2">线下(上传)</a-radio-button>
    </a-radio-group>
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="importData" :loading="loading">
      <template #icon>
        <PlusCircleOutlined />
      </template>导入报名数据
    </a-button>
    <a-button type="primary" @click="exportData" :loading="loading">
      <template #icon>
        <DownOutlined />
      </template>导出报名数据
    </a-button>
  </div>
  <a-table :columns="columns" :data-source="data" :loading="dataLoading" :scroll="{ y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }">
    <template #bodyCell="{ index, record, column }">
      <template v-if="column.dataIndex === 'index'">
        {{ index + 1 }}
      </template>
      <template v-if="column.dataIndex === 'name'">
        <p> {{ record.name }} </p>
        <p v-if="record.paystate === 1 || record.from === 'import'" class="text-success">
          已支付
        </p>
        <p v-else-if="record.paystate === 0" class="text-error">
          未支付
        </p>
      </template>
      <template v-if="column.dataIndex === 'pname'">
        <p v-for="player in record.players" :key="player._id">
          {{ player.name }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'gwork'">
        <p v-for="(player, index) in record.players" :key="player._id">
          {{ index === 0 ? '队长' : '队员' }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'nation'">
        <p v-for="(player) in record.players" :key="player._id">
          {{ player.nation }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'sex'">
        <p v-for="(player) in record.players" :key="player._id">
          {{ player.sex }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'certificate'">
        <p v-for="(player) in record.players" :key="player._id">
          {{ player.certificate }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'mobile'">
        <p v-for="(player) in record.players" :key="player._id">
          {{ player.mobile }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'email'">
        <p v-for="(player) in record.players" :key="player._id">
          {{ player.email }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'clothes_size'">
        <p v-for="(player) in record.players" :key="player._id">
          {{ player.clothes_size }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <div class="edites">
            <p v-for="(player) in record.players" :key="player._id"><a href="javascript:;"
                @click="editeItem(record._id, player)">
                编辑
              </a>
            </p>
          </div>
          <div v-if="record.paystate === 0" class="deletes">
            <a-popconfirm title="删除报名信息" @confirm="remove(record)">
              <a href="javascript:;" class="text-error">删除</a>
            </a-popconfirm>
          </div>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-drawer title="编辑报名信息" :visible="signdataModal" :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }" @close="cancelSave" width="30%" :closable="!saving" :maskClosable="!saving">
    <template #extra>
      <a-button class="mr-2" @click="cancelSave" :disabled="saving">取消</a-button>
      <a-button type="primary" @click="saveSigndata" :loading="saving">保存</a-button>
    </template>
    <a-form ref="formRef" name="signForm" :model="signForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }"
      autocomplete="off">
      <a-form-item label="姓名" name="name" :rules="[{ required: true, message: '姓名不能为空' }]">
        <a-input v-model:value="signForm.name" :disabled="saving" />
      </a-form-item>
      <a-form-item label="民族" name="nation" :rules="[{ required: true, message: '民族不能为空' }]">
        <a-input v-model:value="signForm.nation" :disabled="saving" />
      </a-form-item>
      <a-form-item label="性别" name="sex" :rules="[{ required: true, message: '性别不能为空' }]">
        <a-select v-model:value="signForm.sex" :options="sexs" placeholder="请选择性别" :disabled="saving" show-search>
        </a-select>
      </a-form-item>
      <a-form-item label="身份证号" name="certificate" :rules="[{ required: true, message: '身份证号不能为空' }]">
        <a-input v-model:value="signForm.certificate" :disabled="saving" />
      </a-form-item>
      <a-form-item label="手机号" name="mobile" :rules="[{ required: true, message: '手机号不能为空' }]">
        <a-input v-model:value="signForm.mobile" :disabled="saving" />
      </a-form-item>
      <a-form-item label="T恤尺寸" name="clothes_size" :rules="[{ required: true, message: 'T恤尺寸不能为空' }]">
        <a-select v-model:value="signForm.clothes_size" :options="clothes_sizes" placeholder="请选择T恤尺寸" :disabled="saving"
          show-search>
        </a-select>
      </a-form-item>
      <a-form-item label="邮箱" name="email">
        <a-input v-model:value="signForm.email" :disabled="saving" />
      </a-form-item>
    </a-form>
  </a-drawer>
  <UploadTask ref="uploadtaskRef" />
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
import UploadTask from '@/components/uploadtask.vue';
const columns = [
  { title: '序号', dataIndex: 'index', width: 50, },
  { title: '线路', dataIndex: 'line', width: 100, },
  { title: '队伍名称', dataIndex: 'name', width: 200, },
  { title: '姓名', dataIndex: 'pname', width: 100, },
  { title: '职务', dataIndex: 'gwork', width: 60, },
  { title: '民族', dataIndex: 'nation', width: 60, },
  { title: '性别', dataIndex: 'sex', width: 60, },
  { title: '身份证号', dataIndex: 'certificate', width: 200, },
  { title: '手机号', dataIndex: 'mobile', width: 120, },
  { title: 'T恤尺寸', dataIndex: 'clothes_size', width: 120, },
  { title: '邮箱', dataIndex: 'email', width: 150, },
  { title: '报名时间', dataIndex: 'create_time', width: 180, },
  { title: '来源', dataIndex: 'source', width: 120, },
  { title: '操作', dataIndex: 'operation', width: 120, fixed: 'right', align: 'center' },
];
export default defineComponent({
  name: 'SignData',
  components: { DownOutlined, SearchOutlined, PlusCircleOutlined, UploadTask, },
  setup() {
    const { proxy } = getCurrentInstance();
    const uploadtaskRef = ref();
    const formRef = ref();
    const state = reactive({
      queryForm: {
        searchtext: "",
        lineid: "0",
        paystate: "0",
        from: "0",
      },
      signForm: {
        _id: '',
        name: '',
        nation: '',
        sex: '',
        certificate: '',
        mobile: '',
        clothes_size: '',
        email: ''
      },
      signdataModal: false,
      saving: false,
      match_lines: [],
      sexs: [{ text: '男', value: '男' }, { text: '女', value: '女' }],
      clothes_sizes: [{ text: 'S', value: 'S' }, { text: 'M', value: 'M' }, { text: 'L', value: 'L' }, { text: 'XL', value: 'XL' }, { text: 'XXL', value: 'XXL' }, { text: 'XXXL', value: 'XXXL' }, { text: 'XXXXL', value: 'XXXXL' }],
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      data: [],
      dataLoading: false,
      loading: false,
    });
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    onActivated(() => {
      getData();
    });
    const editeItem = (teamid, item) => {
      state.signForm = {
        teamid,
        _id: item._id,
        name: item.name,
        nation: item.nation,
        sex: item.sex,
        certificate: item.certificate,
        mobile: item.mobile,
        clothes_size: item.clothes_size,
        email: item.email,
      };
      state.signdataModal = true;
    }
    const cancelSave = () => {
      formRef.value.clearValidate();
      state.signdataModal = false;
    }
    const saveSigndata = () => {
      state.saving = true;
      proxy.$api.doAPI("/match/savesigndata", { signForm: state.signForm, }).then((res) => {
        state.saving = false;
        if (res.code === 0) {
          getData();
          state.signdataModal = false;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.saving = false;
        console.error(err);
      });
    }
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/match/signdata", { page: state.page, limit: state.limit, queryForm: state.queryForm, }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              line: _.findWhere(res.data.match.match_lines, { _id: n.lineid })?.name,
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
          state.match_lines = res.data.match.match_lines;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    ///导入数据
    const importData = () => {
      uploadtaskRef.value.showUpload({ title: "导入报名数据", file: "报名模板.xlsx", type: "import_signdata" });
    }
    ///导出数据
    const exportData = () => {
      state.loading = true;
      proxy.$api.doAPI("/match/exportsigndata").then((res) => {
        state.loading = false;
        if (res.code === 0) {
          proxy.$utils.download(res.data.url, res.data.name);
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.loading = false;
        console.error(err);
      });
    }
    ///删除队伍
    const remove = (item) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/match/removesigndata", { _id: item }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    return {
      ...toRefs(state),
      columns,
      uploadtaskRef,
      formRef,
      getData,
      remove,
      importData,
      exportData,
      cancelSave,
      editeItem,
      saveSigndata
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.ant-table-tbody > tr > td) {
  padding: 8px 8px !important;
}

:deep(.ant-table-title) {
  padding: 0;
}

:deep(.ant-switch) {
  background-color: #ff4d4f;
}

:deep(.ant-switch-checked) {
  background-color: #52c41a;
}

p {
  margin-bottom: 5px;
}
</style>

