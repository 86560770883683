const _ = require('underscore');
const state = {
  menus: [
    {
      key: "0",
      title: "赛事信息",
      url: "/home",
      roles: [],
    },
    {
      key: "1",
      title: "报名数据",//赛事报名数据
      url: "/signdata",
      roles: [],
    },
  ],
  pages: [

  ]
};
//获取菜单路由链
function getMenuChain(menus, url) {
  for (let i = 0; i < menus.length; i++) {
    let menu1 = menus[i];
    if (menu1.url === url) {
      return { menu1 };
    } else if (menu1.children?.length > 0) {
      for (let j = 0; j < menu1.children.length; j++) {
        let menu2 = menu1.children[j];
        if (menu2.url === url) {
          return { menu1, menu2 };
        } else if (menu2.children?.length > 0) {
          for (let k = 0; k < menu2.children.length; k++) {
            let menu3 = menu2.children[k];
            if (menu3.url === url) {
              return { menu1, menu2, menu3 };
            }
          }
        }
      }
    }
  }
  return {};
}
function checkRole(roles, role) {
  return !Array.isArray(roles) || roles.length === 0 || roles.indexOf(role) > -1;
}
function checkMenuPermission(menu, { username, role }) {
  if (menu.username) {
    let result = menu.username === username;
    if (menu.env_OS) result = result && (menu.env_OS === process.env.OS);
    return result;
  }
  return (role === "root" || role === "admin" || checkRole(menu.roles, role))
}
function checkPagePermission(page, { username, role }) {
  if (page.username) {
    let result = page.username === username;
    if (page.env_OS) result = result && (page.env_OS === process.env.OS);
    return result
  }
  return (level === "root" || role === "admin" || checkRole(menu.roles, role))
}
function checkTopMenu(menus, { username, role }) {
  let result1 = [];
  for (let i = 0; i < menus.length; i++) {
    let menu1 = menus[i];
    if (checkMenuPermission(menu1, { username, role })) {
      if (menu1.children?.length > 0) {
        let result2 = [];
        for (let j = 0; j < menu1.children.length; j++) {
          let menu2 = menu1.children[j];
          if (checkMenuPermission(menu2, { username, role })) {
            if (menu2.children?.length > 0) {
              let result3 = [];
              for (let k = 0; k < menu2.children.length; k++) {
                let menu3 = menu2.children[k];
                if (checkMenuPermission(menu3, { username, role })) {
                  result3.push({ ...menu3 });
                }
              }
              if (result3.length > 0) result2.push({ ...menu2, children: [...result3] });
            } else {
              result2.push({ ...menu2 });
            }
          }
        }
        if (result2.length > 0) result1.push({ ...menu1, children: [...result2] });
      } else {
        result1.push({ ...menu1 });
      }
    }
  }
  return result1;
}
function checkLeftMenu(menus, { username, role }) {
  let result1 = [];
  for (let i = 0; i < menus.length; i++) {
    let menu1 = menus[i];
    if (checkMenuPermission(menu1, { username, role })) {
      if (menu1.children?.length > 0) {
        let result2 = [];
        for (let j = 0; j < menu1.children.length; j++) {
          let menu2 = menu1.children[j];
          if (checkMenuPermission(menu2, { username, role })) {
            result2.push({ ...menu2 });
          }
        }
        if (result2.length > 0) result1.push({ ...menu1, children: [...result2] });
      } else {
        result1.push({ ...menu1 });
      }
    }
  }
  return result1;
}
const getters = {
  getTopMenus: (state, getters, rootState) => () => {
    // console.time("getTopMenus");
    let menus = checkTopMenu(state.menus, rootState.user);
    // console.timeEnd("getTopMenus");
    return menus;
  },
  getMenuChain: (state) => (url) => {
    // console.time("getMenuChain");
    let chain = getMenuChain(state.menus, url);
    // console.timeEnd("getMenuChain");
    return chain;
  },
  getLeftMenus: (state, getters, rootState) => (key) => {
    // console.time("getLeftMenus");
    let menus = _.findWhere(state.menus, { key })?.children;
    if (menus && menus.length > 0) {
      // console.timeEnd("getLeftMenus");
      return checkLeftMenu(menus, rootState.user);
    }
    // console.timeEnd("getLeftMenus");
    return [];
  },
};
const mutations = {};
function checkMenu(menus, pages, url, { username, role }) {
  for (let i = 0; i < menus.length; i++) {
    let menu1 = menus[i];
    if (checkMenuPermission(menu1, { username, role })) {
      if (menu1.url === url) return true;
      if (menu1.children?.length > 0) {
        for (let j = 0; j < menu1.children.length; j++) {
          let menu2 = menu1.children[j];
          if (checkMenuPermission(menu2, { username, role })) {
            if (menu2.url === url) return true;
            if (menu2.children?.length > 0) {
              for (let k = 0; k < menu2.children.length; k++) {
                let menu3 = menu2.children[k];
                if (checkMenuPermission(menu3, { username, role })) {
                  if (menu3.url === url) return true;
                }
              }
            }
          }
        }
      }
    }
  }
  for (let i = 0; i < pages.length; i++) {
    let page = pages[i];
    if (checkPagePermission(page, { username, level })) {
      if (page.url === url) return true;
    }
  }
  return false;
}
const actions = {
  checkMenu({ state, commit, rootState }, url) {
    return checkMenu(state.menus, state.pages, url, rootState.user);
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
