import _ from "underscore";
import { Modal, message } from "ant-design-vue";
import axios from 'axios';
import moment from 'moment';
moment.locale('zh_cn');
import store from './store';
import { v4 as uuidv4 } from 'uuid';

function alert(text) {
  return Modal.info({ title: "提示", content: text });
}
function dateFormat(date, pattern = "YYYY-MM-DD") {
  date = moment(date);
  if (date.isValid()) return date.format(pattern);
  return "";
}
function download(url, name = '') {
  message.loading("正在下载", 0);
  getBlob(url).then((blob) => {
    saveBlob(blob, name);
  }).catch(() => {
    message.destroy();
    message.fail("下载失败");
  });
}
function downloadBrowser({ name, href }) {
  var a = document.createElement("a"),
    e = document.createEvent("MouseEvents");
  e.initEvent("click", false, false);
  a.href = href;
  a.target = "_blank";
  if (name) a.download = encodeURIComponent(name);
  a.dispatchEvent(e);
}
function formatMoney(number, places, symbol, thousand, decimal) {
  number = Number(number) || 0;
  places = !isNaN(places = Math.abs(places)) ? places : 0;
  symbol = symbol !== undefined ? symbol : "￥";
  thousand = thousand || ",";
  decimal = decimal || ".";
  var negative = number < 0 ? "-" : "";
  var i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
    j = (j = i.length) > 3 ? j % 3 : 0;
  return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
}
function getBlob(url) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url,
      responseType: 'blob'
    }).then(data => {
      resolve(data.data);
    }).catch(error => {
      console.error(error);
      resolve(null);
    })
  })
}
function saveBlob(blob, name) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, name);
    message.destroy();
    message.success("下载完成");
  } else {
    const link = document.createElement('a');
    const body = document.querySelector('body');
    link.href = window.URL.createObjectURL(blob); // 创建对象url
    link.download = name;
    // fix Firefox
    link.style.display = 'none';
    body.appendChild(link);
    link.click();
    body.removeChild(link);
    window.URL.revokeObjectURL(link.href); // 通过调用 URL.createObjectURL() 创建的 URL 对象
    message.destroy();
    message.success("下载完成");
  }
}
async function setStore({ userid, username, name, role }) {
  localStorage.setItem("zzdxs_admin.userid", userid);
  localStorage.setItem("zzdxs_admin.username", username);
  localStorage.setItem("zzdxs_admin.name", name);
  localStorage.setItem("zzdxs_admin.role", role);
  await store.dispatch("user/setUser", { userid, username, name, role });
}
function sliceFile(size) {
  let chunksize = 512 * 1024;//切片大小 512k
  let res = [];
  if (size <= chunksize) {
    res.push({ chunk: 0, chunks: 1 });
  } else {
    let chunks = size % chunksize == 0 ? Math.floor(size / chunksize) : Math.floor(size / chunksize) + 1;
    for (let i = 0; i < chunks; i++) {
      res.push({ chunk: i, chunks, from: chunksize * i, to: (i == chunks - 1) ? size : chunksize * (i + 1) });
    }
  }
  return res;
}
function sleep(ts = 1000) {
  return new Promise(resolve => {
    setTimeout(resolve, ts);
  });
}
function twidth(columns) {
  return _.reduce(columns, (a, b) => a + (b.width || 100), 0);
}
function uuid() {
  return uuidv4().replace(/-/g, '');
}
export default {
  alert,
  download,
  downloadBrowser,
  dateFormat,
  formatMoney,
  setStore,
  sliceFile,
  sleep,
  twidth,
  uuid,
};