<template>
  <router-view v-slot="{ Component, route }">
    <keep-alive>
      <component :key="route.name" :is="Component" />
    </keep-alive>
  </router-view>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "EmptyLayout",
});
</script>
