<template>
  <div class="exception">
    <div class="imgBlock">
      <img style="margin-left: 20px" src="https://scdn.xidong360.com/404.png" alt />
    </div>
    <div class="content">
      <h1>404</h1>
      <div class="desc">抱歉，你访问的页面不存在或仍在开发中</div>
      <div class="actions">
        <a style="color: #13c2c2" @click="gohome"> 返回首页 </a>
      </div>
    </div>
  </div>
</template>

<script>
import { message, Modal } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "404",
  setup() {
    const router = useRouter();

    const gohome = () => {
      router.push({ path: "/" }).catch(console.error);
    };
    return {
      gohome,
    };
  },
});
</script>
<style lang="less" scoped>
.exception {
  display: flex;
  align-items: center;
  height: 80%;
  min-height: 500px;

  .imgBlock {
    flex: 0 0 62.5%;
    width: 62.5%;
    padding-right: 152px;
    zoom: 1;

    &::before,
    &::after {
      content: " ";
      display: table;
    }

    &::after {
      clear: both;
      height: 0;
      font-size: 0;
      visibility: hidden;
    }
  }

  .content {
    flex: auto;

    h1 {
      margin-bottom: 24px;
      color: #434e59;
      font-weight: 600;
      font-size: 72px;
      line-height: 72px;
    }

    .desc {
      margin-bottom: 16px;
      color: #848587;
      font-size: 20px;
      line-height: 28px;
    }

    .actions {
      button:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
</style>
